import { Injectable } from '@angular/core';

import axios from 'axios';

const getClient = () => {
  const client = window.location.hostname.split('.')[0];

  return client === 'localhost' ? 'ja' : client;
};

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  constructor() { }

  public configServEtl = axios.create({
    baseURL: ['https://backend.terus.tec.br', getClient()].join('/'),
    headers: {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"},
    timeout: 60000
  });

  public configServEtlPW = axios.create({
    baseURL: ['https://backend.terus.tec.br', getClient() + 'pw'].join('/'),
    headers: {"Authorization": "Basic QXBwRXRsMmI6UE9PRkNJR01JUkxPU09NTFlQSlRIWUtFTkFNTE1Q", "Content-Type": "application/json"},
    timeout: 60000
  });

  public configServEtl2b = axios.create({
    baseURL: 'http://servicos.etl2b.com.br:41650/',/*http://cloud.etl2b.com.br:41653/',*/
    headers: {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"},
    timeout: 60000
  });
}