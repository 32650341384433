<div class="row-fluid">
  <div class="sidebar fechar">
    <div class="logo-details" (click)="homehandle()">
      <!-- <fa-icon [icon]="faBuilding"></fa-icon> -->
      <div class="logo-img">
          <img src="../../assets/logos/Logo Terus-02.png" alt="logo-Etl2b">
      </div>
      <span class="logo_name">ETL2B</span>
    </div>
    <ul class="nav-links">
      <li  class="home" *ngIf="nivel <= '4'">
        <div class="iocn-links">
          <a>
            <fa-icon [icon]="faChartBar"></fa-icon>
            <span class="link_name">Dashboard</span>
          </a>
          <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
        </div>
        <ul class="sub-menu">
          <li *ngFor="let item of infoMenuUser" id="dash{{item.id}}">
            <div class="li-sub-menu back-cabecalho" (click)="espandeSubMenu('dash'+item.id,'sub-menu-sub')">
              <a class="link_name">{{item.descricao}}</a>
              <fa-icon [icon]="faAngleDown" class="arrow-dashboard"></fa-icon>
            </div>
            <hr class="hr-item">
            <ul class="sub-menu-sub" *ngIf="item.itens">
              <li *ngFor="let itemSub of item.itens; last as isLast" id="dash{{itemSub.id}}">
                <!-- quando possuir itens -->
                <div class="li-sub-menu-itens back-items" *ngIf="itemSub.itens != null && itemSub.itens != ''" (click)="espandeSubMenu('dash'+itemSub.id,'sub-menu-sub-item')" >
                  <a class="link_name">{{itemSub.descricao}}</a>
                  <fa-icon [icon]="faAngleDown" class="arrow-dashboard"></fa-icon>
                </div>
                <!-- nao possui itens -->
                <div class="li-sub-menu" *ngIf="itemSub.itens == null || itemSub.itens == ''" >
                  <fa-icon [icon]="itemSub.icone=='TABLE'? faTable:faChartBar"></fa-icon>
                  <a class="link_name" (click)="carregaPergunta(itemSub.descricao,itemSub.config_dash,itemSub.filter_dash)">{{itemSub.descricao}}</a>
                </div>
                <hr class="hr-subitem" *ngIf="!isLast">
                <ul class="sub-menu-sub-item" *ngIf="itemSub.itens != null && itemSub.itens != ''">
                  <li *ngFor="let itemSubSub of itemSub.itens; last as isLastSub" id="dash{{itemSubSub.id}}">
                    <!-- <div class="li-sub-menu">
                      <fa-icon [icon]="faChartBar"></fa-icon>
                      <a class="link_name" (click)="carregaPergunta(itemSubSub.descricao,itemSubSub.config_dash,itemSubSub.filter_dash)">{{itemSubSub.descricao}}</a>
                    </div> -->
                    <!-- quando possuir itens -->
                    <div class="li-sub-menu-itens back-items-nivel3" *ngIf="itemSubSub.itens != null && itemSubSub.itens != ''" (click)="espandeSubMenu('dash'+itemSubSub.id,'sub-menu-sub-item-nivel4')" >
                      <a class="link_name">{{itemSubSub.descricao}}</a>
                      <fa-icon [icon]="faAngleDown" class="arrow-dashboard"></fa-icon>
                    </div>
                    <!-- nao possui itens -->
                    <div class="li-sub-menu" *ngIf="itemSubSub.itens == null || itemSubSub.itens == ''" >
                      <fa-icon [icon]="faChartBar"></fa-icon>
                      <a class="link_name" (click)="carregaPergunta(itemSubSub.descricao,itemSubSub.config_dash,itemSubSub.filter_dash)">{{itemSubSub.descricao}}</a>
                    </div>
                    <ul class="sub-menu-sub-item-nivel4" *ngIf="itemSub.itens != null && itemSub.itens != ''">
                      <li *ngFor="let itemSubSubSub of itemSubSub.itens; last as isLastSubSub">
                        <div class="li-sub-menu">
                          <fa-icon [icon]="faChartBar"></fa-icon>
                          <a class="link_name" (click)="carregaPergunta(itemSubSubSub.descricao,itemSubSubSub.config_dash,itemSubSubSub.filter_dash)">{{itemSubSubSub.descricao}}</a>
                        </div>
                        <hr class="hr-subitemsub" *ngIf="!isLastSubSub">
                        <!-- <hr class="hr-subitem" *ngIf="isLastSubSub"> -->
                      </li>
                    </ul>
                    <hr class="hr-subitemsub" *ngIf="!isLastSub">
                    <hr class="hr-subitem" *ngIf="isLastSub">
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li (click)="espandeMenu('cadastro')" class="cadastro" *ngIf="nivel <= '2'">
          <div class="iocn-links">
              <a>
                  <fa-icon [icon]="faAddressCard"></fa-icon>
                  <span class="link_name">Cadastros</span>
              </a>
              <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
          </div>
          <ul class="sub-menu">
              <li><a class="link_name" >Cadastros</a></li>
              <li><a (click)="redireciona('cadEmpresas')">Empresas</a></li>
              <li><a (click)="redireciona('cadGrupos')">Grupos Corporativos</a></li>
              <li><a (click)="redireciona('cadUsuarios')">Usuários</a></li>
              <li><a (click)="redireciona('cadFuncoes')">Funções</a></li>
              <li><a (click)="redireciona('cadTurnos')">Turnos</a></li>
          </ul>
      </li>
      <li (click)="espandeMenu('listaAtividades')" class="cadastro" *ngIf="nivel <= '3'">
        <div class="iocn-links">
            <a>
                <fa-icon [icon]="faTasks"></fa-icon>
                <span class="link_name">Terus Teams</span>
            </a>
            <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
        </div>
        <ul class="sub-menu">
          <li><a class="link_name">Terus Teams</a></li>
          <li><a (click)="redireciona('listaAtividades')">Log de Atividades</a></li>
          <!-- <li><a (click)="redireciona('criaAtividades')">Criar Atividades</a></li> -->
        </ul>
      </li>
      <li (click)="redireciona('quemSomos')">
        <div class="quem-somos">
          <a>
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
          </a>
          <ul class="sub-menu blank">
              <li><a class="link_name">ETL2B Technology Ltda</a></li>
          </ul>
        </div>
      </li>
      <!-- <li (click)="espandeMenu('listaAtividades')" class="cadastro" *ngIf="nivel <= '2'">
        <div class="iocn-links">
            <a>
                <fa-icon [icon]="faThumbtack"></fa-icon>
                <span class="link_name">TaskWeb</span>
            </a>
            <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
        </div>
        <ul class="sub-menu">
          <li><a class="link_name">TaskWeb</a></li>
          <li><a (click)="redireciona('taskweb')">Atividades</a></li>
        </ul>
      </li> -->

      <li (click)="espandeMenu('listaAtividades')" class="cadastro" *ngIf="nivel <= '1'">
        <div class="iocn-links" (click)="redireciona('listaPedidosFornecedor')">
            <a>
                <fa-icon [icon]="faBoxesPacking"></fa-icon>
                <span class="link_name">Terus CheckMe</span>
            </a>
            <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
        </div>
        <ul class="sub-menu">
          <li><a class="link_name">Terus CheckMe</a></li>
          <li><a (click)="redireciona('listaPedidosFornecedor')">Pedidos Fornecedor</a></li>
          <li><a (click)="redireciona('produtos')">Produtos</a></li>
        </ul>
      </li>

      <li (click)="redireciona('quemSomos')">
        <div class="quem-somos">
          <a>
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
          </a>
          <ul class="sub-menu blank">
              <li><a class="link_name">TERUSTec Soluções para o Varejo.</a></li>
          </ul>
        </div>
      </li>

      <li>
        <div class="profile-details">
          <div class="profile-content" (click)="espandeSideBar()">
            <img src="../../assets/logos/userProfile.png" alt="ImgProfileUser">
          </div>
          <div class="name-job">
            <div class="profile_name">{{ this.infoUser.nome }}</div>
            <div class="job">{{ this.infoUser.nome }}</div>
          </div>
          <a href="#"><fa-icon [icon]="faSignOutAlt"></fa-icon></a>
          <ul class="sub-menu blank">
            <li><a class="link_name">{{ this.infoUser.nome }}</a></li>
            <!-- <li><a class="">Perfil</a></li> -->
            <li><a (click)="redireciona('')">Sair</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <!-- <div class="modal-quemsomos">
    <app-quem-somos ></app-quem-somos>
  </div> -->

  <!-- <div class="header-content">
      <div class="profileUser" (click)="espandeMenu('header-content .profileUser')">
        <div class="dropdown-profiler">
          <img src="../../assets/logos/userProfile.png" alt="ImgProfileUser">
          <span class="nameUser">{{ this.infoUser.nome }}</span>
          <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
        </div>

        <ul class="sub-menu">
          <li><a href="#"><fa-icon [icon]="faCog"></fa-icon>Configuração</a></li>
          <li><a href="#"><fa-icon [icon]="faPowerOff"></fa-icon>Sair</a></li>
        </ul>
      </div>
  </div> -->

  <!-- <div class="dashboard-header home-content">
    <fa-icon [icon]="faBars" (click)="espandeSideBar()"></fa-icon>
    <app-seleciona-cliente></app-seleciona-cliente>
    <nav class="navbar navbar-expand-lg bg-white fixed-top">
      <div style="max-width: 450px;">
        <a class="navbar-brand" href="/home" style="display: flex;justify-content: flex-start;align-items: center;color: #333333;">
          <!-- <span class="titulo-portal">Portal</span>
          <img src="../../assets/logos/SAO-LUIZ-1.png" alt="home" class="titulo-img">
        </a>
      </div>
      <div class="collapse navbar-collapse " id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto navbar-right-top">
          <li *ngIf="nomeFantasiaClienteSelecionado">
            <div class="info-cliente" (click)="filtroClienteSelecionado()">
                <span class="info-nome-cliente">{{nomeFantasiaClienteSelecionado}}</span>
                <span class="info-endereco-cliente">{{ennderoClienteSelecionado}}</span>
            </div>
          </li>
          <li id="dropdowUser" class="nav-item dropdown nav-user" (click)="dropdowOpen('profile')">
            <div class="nav-link nav-user-img" style="display: flex;align-items: center; min-width: 260px;max-height: 58px;border-left: 1px solid #d8d1d1;" role="button">
              <img src="https://banner2.cleanpng.com/20180701/eta/kisspng-computer-icons-user-profile-avatar-icon-5b3899483fa7a8.4711163815304359122607.jpg" alt="" class="user-avatar-md rounded-circle" style="margin-right: 13px;">
              <div class="nome-usuario">
                <h5 class="mb-0 text-black nav-user-name">{{ this.infoUser.nome }}</h5>
              </div>
            </div>
            <div id="profile" class="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
              <div class="dropdown-item" (click)="filtroClienteSelecionado()" style="background: #cfe4f7;cursor: pointer;">
                <div>
                  <i class="fas fa-address-card mr-2"></i>
                  <span>Selecionar CNPJ:</span>
                </div>
                <div class="descProfile">
                  <span>{{nomeFantasiaClienteSelecionado}}</span>
                </div>
              </div>
              <a class="dropdown-item" href="#"><i class="fas fa-cog mr-2"></i>Configuração</a>
              <a class="dropdown-item" href="#"><i class="fas fa-power-off mr-2"></i>Sair</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div> -->
</div>