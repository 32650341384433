import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Encryption } from './../../util/encryption';
import { ApiConfigService } from './../../sevice/api-config.service';

import { User } from './../../models/user.model';
import { IGrupos } from '../../models/IGrupos.model';
import { IContatos } from 'src/app/models/IContatos.model';
import { IEmpresas } from 'src/app/models/IEmpresas.model';
import { IUsuarios } from './../../models/IUsuarioCad.models';
import { IFuncoes } from './../../models/IFuncoes.model';
import { ITurnos } from './../../models/ITurnos.model';

@Injectable({
  providedIn: 'root'
})
export class CadastroApiService {

  private urlEtl2b = 'http://servicos.etl2b.com.br:41650/';
  // private urlEtl2b = 'http://10.200.16.11:5091/';

  infoUser:User;

  constructor(
    private http: HttpClient,
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  getDados(endpoint, data={"login":"header"}){
    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    // return this.http.post<string>(`${this.urlEtl2b}${endpoint}`, data, {headers, responseType: 'text' as 'json'})
    return this.configApi.configServEtl.post<string, any>(endpoint,data,).then( result => {return result.data});
  }

  getDepartametos(endpoint){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    var data ={
      id_cliente: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa
    }

    // return this.http.post<string>(`${this.urlEtl2b}${endpoint}`, data, {headers, responseType: 'text' as 'json'})
    return this.configApi.configServEtl.post<string, any>(endpoint,data,).then( result => {return result.data});
  }

  getFuncoes(endpoint){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    var data ={
      id_cliente: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa
    }

    // return this.http.post<string>(`${this.urlEtl2b}${endpoint}`, data, {headers, responseType: 'text' as 'json'})
    return this.configApi.configServEtl.post<string, any>(endpoint,data,).then( result => {return result.data});
  }

  getVendedores(){
    return this.configApi.configServEtl.get<string, any>('/fornecedor/vendedores?cnpj_fornecedor=73909566000104').then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  getFornecedores(endpoint){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    var data ={
      id_cliente: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa
    }

    // return this.http.post<string>(`${this.urlEtl2b}${endpoint}`, data, {headers, responseType: 'text' as 'json'})
    return this.configApi.configServEtl.post<string, any>(endpoint,data,).then( result => {return result.data});
  }

  //============================
  // Get Dados Contatos
  //============================
  getDadosContatos(endpoint, idloja = ''){
    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    var data={"idloja": idloja}

    // return this.http.post<string>(`${this.urlEtl2b}${endpoint}`, data, {headers, responseType: 'text' as 'json'});
    return this.configApi.configServEtl.post<string,any>(endpoint,data,).then(response => {return response.data});
  }

  //============================
  // Servicos Grupo de Empresas
  //============================
  async postDadosGrupos(grupo: IGrupos, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    grupo['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_groups_portal`, grupo, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_groups_portal',grupo,).then(response => {
      return response
    });

    return result;
  }

  //======================================
  // Servicos Grupo de Empresas Individual
  //======================================
  async postDadosGruposEmpresas(empresas: IEmpresas, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    // const result = await this.http.post(`${this.urlEtl2b}set_grupos_empresa_portal`, empresas, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_grupos_empresa_portal',empresas,).then(response => {
      return response
    });

    return result;
  }


  //============================
  // Servicos de Empresas/lojas
  //============================
  async postDadosLojas(loja: IEmpresas, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    loja['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_companies_portal`, loja, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_companies_portal',loja,).then(response => {
      return response
    });

    return result;
  }

  //============================
  // Servicos de Contatos
  //============================
  async postDadosContatos(contatos: IContatos, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    contatos['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_contacts_portal`, contatos, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_contacts_portal',contatos,).then(response => {
      return response
    });

    return result;
  }

  //============================
  // Servicos de Funcoes
  //============================
  async postDadosFuncoes(funcoes: IFuncoes, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    funcoes['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_function_operator_portal`, funcoes, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_function_operator_portal',funcoes,).then(response => {
      return response
    });

    return result;
  }

  //============================
  // Servicos de Turnos
  //============================
  async postDadosTurnos(turnos: ITurnos, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    turnos['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_turn_operator_portal`, turnos, {headers}).toPromise().then(response => {
    //   return response;
    // });

    const result = await this.configApi.configServEtl.post<string,any>('set_turn_operator_portal',turnos,).then(response => {
      return response
    });

    return result;
  }

  //============================
  // Servicos Salva Usuarios
  //============================
  async postDadosUsuarios(usuario: IUsuarios, tipo){

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"};

    usuario['tipo'] = tipo;

    // const result = await this.http.post(`${this.urlEtl2b}set_usuarios_portal`, usuario, {headers}).toPromise().then(response => {
    //   return response;
    // });
    console.log(usuario);

    const result = await this.configApi.configServEtl.post<string,any>('set_usuarios_portal',usuario,).then(response => {
      return response
    });

    return result;
  }

  //==============================
  // Servico que gera nova senha
  // para o usuario selecionado
  //==============================
  async getNovaSenha(id_usuario: string){

    var data = {
      id_usuario: id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_nova_senha_usuario', data,).then( response => {
      return response.data.new_password;
    }).catch( error =>{
      console.log(error);
    })
  }

  //============================
  // Servico para preencher o
  // autocomplete de produtos
  //============================
  async getListaProdutos(produto: string = '', empresaSelect: any = ''){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente_agent: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_loja: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_filial_loja_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      produto: produto
    }

    return this.configApi.configServEtl.post<string, any>('get_lista_produto_portal',data,).then( response => {
      return response.data.produtos;
    }).catch( error =>{
      console.log(error);
    })
  }

  //==================================
  // Servico para gerar as atividades
  //==================================
  async postCriaAtividades(dados: any, empresaSelect: any = ''){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente_agent: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_loja: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_filial_loja_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_alerta: dados.id_alerta,
      id_usuario: this.infoUser.id_usuario,
      data_agenda: dados.data_agenda,
      produtos: dados.produtos,
      secoes: dados.secoes
    }

    return this.configApi.configServEtl.post<string, any>('set_cria_atividades_portal',data,).then( response => {
      return response.data;
    }).catch( error =>{
      console.log(error);
    })
  }



}
