import { Injectable } from '@angular/core';

import { User } from './../../../models/user.model';
import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Encryption } from 'src/app/util/encryption';
import { IPedido } from '../../../models/IPedidos';

@Injectable({
  providedIn: 'root'
})
export class AprovaPedidosCheckmeService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  ListaItemPedido(idPedido: string){

    const data = {
      id_pedido: idPedido,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<IPedido[], any>('get_pedidos_checkme_item_portal',data,).then( response => {
      return response.data.pedidos;
    }).catch( error =>{
      console.log(error);
    })
  }

  ListaPlanosPagamento(){
    return this.configApi.configServEtl.get<any[], any>('/fornecedor/planos_pagamentos', {
      params: {
        cnpj_fornecedor: '73909566000104'
      }
    })
      .then( response => response.data)
      .catch( error =>{
        console.log(error);
      })
  }

  setPedidosStatus(idPedido: string, status: string){

    const data = {
      id_pedido: idPedido,
      status: status,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<any>('set_pedidos_checkme',data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }

  listaOpcoes(){

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    const data = {
      id_cliente: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: this.infoUser.id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_info_geral_filtros',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  setPedidos(idPedido: string, pedido: IPedido, status: string){

    const data = {
      id_pedido: idPedido,
      pedido: pedido,
      status: status,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<any>('set_pedidos_checkme',data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }
}
