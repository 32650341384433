import { FormatDate } from './util/format-date';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { OrderByPipe } from './util/order-by.pipe';

import { DataTablesModule } from 'angular-datatables';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//primeng
import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';

import { AppComponent } from './app.component';
import { AccountComponent } from './account/account.component';
import { AppRountingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';

// import { ParticlesModule } from 'angular-particle';
import { Encryption } from './util/encryption';
import { AuthGuard } from './guard/auth.guard';
import { FooterComponent } from './footer/footer.component';
import { SelecionaClienteComponent } from './seleciona-cliente/seleciona-cliente.component';
import { Several } from './util/several';
import { FormatText } from './util/formt-text';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { GruposComponent } from './cadastros/grupos/grupos.component';
import { LojasComponent } from './cadastros/lojas/lojas.component';
import { ContatosComponent } from './cadastros/contatos/contatos.component';
import { FuncoesComponent } from './cadastros/funcoes/funcoes.component';
import { TurnosComponent } from './cadastros/turnos/turnos.component';
import { SecoesComponent } from './cadastros/secoes/secoes.component';
import { EmbeddedComponent } from './embedded/embedded.component';
import { UsuariosComponent } from './cadastros/usuarios/usuarios.component';
import { GeolocationEtl } from './util/geolocationEtl';
import { LoadingComponent } from './loading/loading.component';
import { ListaAtividadesComponent } from './listas/lista-atividades/lista-atividades.component';
import { CriaAtividadesComponent } from './cadastros/cria-atividades/cria-atividades.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { TaskwebComponent } from './taskweb/taskweb.component';
import { PoliticaComponent } from './politica/politica.component';
import { AprovaPedidosCheckmeComponent } from './pages/aprova-pedidos-checkme/aprova-pedidos-checkme.component';
import { ListaPedidosFornecedorComponent } from './pages/lista-pedidos-fornececedor/lista-pedidos-fornecedor.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';

@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SelecionaClienteComponent,
    MenuLateralComponent,
    GruposComponent,
    LojasComponent,
    ContatosComponent,
    FuncoesComponent,
    TurnosComponent,
    SecoesComponent,
    EmbeddedComponent,
    UsuariosComponent,
    LoadingComponent,
    ListaAtividadesComponent,
    CriaAtividadesComponent,
    QuemSomosComponent,
    TaskwebComponent,
    PoliticaComponent,
    AprovaPedidosCheckmeComponent,
    ListaPedidosFornecedorComponent,
    ProdutosComponent,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    AppRountingModule,
    FormsModule,
    NgSelectModule,
    // ParticlesModule,
    BrowserAnimationsModule,
    TableModule,
    HttpClientModule,
    DataTablesModule,
    FontAwesomeModule,
    SplitButtonModule
  ],
  providers: [AuthGuard,Encryption, Several, FormatText, GeolocationEtl,FormatDate],
  bootstrap: [AppComponent]
})
export class AppModule { }
