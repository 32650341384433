import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { Product, ProdutosService } from './service/produtos.service';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { DataTableDirective } from 'angular-datatables';
import { IVendedores } from 'src/app/models/IVendedores.model';
import { Encryption } from 'src/app/util/encryption';

declare let $: any;

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent {
  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  listaProdutos: Product[] = [];
  listaVendedores: IVendedores[] = [];
  listaVendedoresFiltrados: IVendedores[] = [];

  vendedorSelecionado: string = '';

  produtoSelecionado: Product | null = null;
  produtoVendedor: string | null = null;
  produtoMultiploVenda: number = 0;
  produtoTipoCarga: Product['tipo_carga'] | null = null;
  produtoConversaoPedido: number = 0;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptionsMercado: ADTSettings = {
    serverSide: true,
    pagingType: 'full_numbers',
    pageLength: 20,
    lengthChange: false,
    scrollCollapse: true,
    searching: false,
    autoWidth: false,
    ordering: false,
    language: {
      info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
      lengthMenu: "_MENU_ resultados por página",
      loadingRecords: "Carregando...",
      processing: "Processando...",
      zeroRecords: "Nenhum registro encontrado",
      paginate: {
        next: "Próximo",
        previous: "Anterior",
        first: "Primeiro",
        last: "Último"
      },
      aria: {
        sortAscending: ": Ordenar colunas de forma ascendente",
        sortDescending: ": Ordenar colunas de forma descendente"
      }
    },
    data: this.listaProdutos,
    ajax: (context: any, callback: any) => {
      this.apiListaPedidos.listaProdutos({
        vendedor: this.vendedorSelecionado,
        page: (context.start || 0) / context.length + 1,
        pesquisa: this.pesquisa,
      }).then(produtos => {
        callback({
          recordsTotal: produtos.total,
          recordsFiltered: produtos.total,
          data: produtos.result
        });
      });
    },
    columns: [
      {
        title: "Produto",
        data: "descricao_produto",
      },
      {
        title: "ID Fornecedor",
        data: "codigo_produto_fornecedor",
      },
      {
        title: "EAN",
        data: "ean_produto"
      }
    ],
    rowCallback: (row: Element, data: Product, index: number) => {
      row.removeEventListener('dblclick', () => { });
      row.addEventListener('dblclick', () => {
        this.produtoSelecionado = data;
        this.produtoVendedor = data['id_vendedor'];
        this.produtoMultiploVenda = data['multiplo_de_venda'];
        this.produtoTipoCarga = data['tipo_carga'];
        this.produtoConversaoPedido = data['conversao_pedido'];

        this.openModal();
      });
    },
  };

  pesquisa: string;
  ativaLoading: boolean = false;
  key: string = 'data_pedido_sem_formato';
  order: string = 'desc';
  matMenuTimer: any;

  input$ = new Subject<string>();

  idSelecionado: string = '';

  bufferSize = 50;

  faRotateRight = faRotateRight;

  constructor(
    private apiListaPedidos: ProdutosService,
  ) { }

  ngOnInit(): void {
    this.apiListaPedidos.listaVendedores().then(result => {
      this.listaVendedores = (result as IVendedores[])
        .sort((vendedor1, vendedor2) => vendedor1.nome_vendedor.trim().localeCompare(vendedor2.nome_vendedor.trim()));

      this.listaVendedoresFiltrados = this.listaVendedores
        .filter((vendedor) => vendedor.id_usuarios && vendedor.id_usuarios.includes(this.apiListaPedidos.infoUser.id_usuario))
    })
  }

  reload(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  openModal(): void {
    $('#ModalChangeProduto').modal('show');
  }

  closeModal(): void {
    $('#ModalChangeProduto').modal('hide');

    this.produtoSelecionado = null;
    this.produtoVendedor = null;
    this.produtoMultiploVenda = 1;
    this.produtoTipoCarga = null;
    this.produtoConversaoPedido = 1;
  }

  saveProduct(): void {
    if (!this.produtoSelecionado) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Selecione um produto para salvar'
      });
      return;
    }

    this.apiListaPedidos.setProduto({
      data: {
        ...this.produtoSelecionado,
        id_vendedor: this.produtoVendedor,
        multiplo_de_venda: this.produtoMultiploVenda,
        tipo_carga: this.produtoTipoCarga,
        conversao_pedido: this.produtoConversaoPedido
      }
    }).then(() => {
      this.Toast.fire({
        icon: 'success',
        title: 'Produto salvo com sucesso'
      });
      this.closeModal();
    }).catch(() => {
      this.Toast.fire({
        icon: 'error',
        title: 'Erro ao salvar produto'
      });
    }).finally(() => {
      this.reload();
    });
  }
}
