export class FormatDate {
    formatDate(date, backdays) {
        date.setDate(date.getDate() - backdays);
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('');
    }
    formatDateForInput(date, backdays) {
        date.setDate(date.getDate() - backdays);
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    firstDayOfMonth(date){
        date.setDate(1);
        return this.formatDateForInput(date, 0);
    }
    formtDateText(date:string){
        if(date){
            return date.substring(6,8)+'/'+date.substring(4,6)+'/'+date.substring(0,4)
        }else{
            return '/ /';
        }
    }
}